<template>
  <navbar>
    <!-- Screen Size Widget -->
    <v-breadcrumbs
      :items="displayDevices"
      divider="|"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled">
          <div 
            class="select-device"
            :style="item.id === displayedDevice ? 'background: #E8F4F5' : ''"
            @click="displayedDevice = item.id"
          >
            <v-icon :color="item.id === displayedDevice ? 'primary' : ''">
              {{ item.icon }}
            </v-icon>
          </div>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <!-- Button -->
    <v-btn
      color="primary"
      class="text-capitalize"
    >
      Edit this site
    </v-btn>
  </navbar>
</template>

<script>
import useAppConfig from '@/@core/@app-config/useAppConfig'

import { mdiCellphone, mdiMonitor, mdiTablet } from '@mdi/js'
import Navbar from '@/views/build-landing-page/components/Navbar.vue'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: { mdiCellphone, mdiMonitor, mdiTablet },
      isDark,
    }
  },
  components: { Navbar },
  data() {
    return {
      displayDevices: [
        {
          id: 'desktop',
          icon: this.icons.mdiMonitor,
          disabled: false,
        },
        {
          id: 'tablet',
          icon: this.icons.mdiTablet,
          disabled: false,
        },
        {
          id: 'phone',
          icon: this.icons.mdiCellphone,
          disabled: false,
        },
      ],
      displayedDevice: 'desktop',
    }
  },
}
</script>

<style lang="scss" scoped>
  .select-device {
    padding: 10.5px 9px;
    border-radius: 4px;
    cursor: pointer;
  }

  // Still figuring out the selector
  .v-breadcrumbs .v-breadcrumbs__divider {
    cursor: default;
  }
</style>
